import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {Box, Button, Checkbox, FormControlLabel, InputLabel } from "@mui/material";
import UserAutocomplete from "../../Autocomplete/User";
import Row from "../../Common/Row";
import OrderAutocomplete from "../../Autocomplete/Order";
import OrderProductsAutocomplete from "../../Autocomplete/OrderProducts";
import api from "../../../api";
import { Paths, LoadingStatuses } from "../../../constants";
import {useHistory, useLocation} from "react-router-dom";
import { useModal } from "../../../hooks";
import CircularPreloader from "../../Common/CircularPreloader";
import { useSnackbar } from "notistack";
import Input from "../../Common/Input/Input";
import { Order } from "../../../api/models";

const schema = yup.object({
    fio: yup.object({
        label: yup.string().required("Обязательное поле"),
    }).required("Обязательное поле"),
    // fio: yup
    // .number()
    // .transform((_, val) => Number(val.value.id))
    // .required("Обязательное поле"),
    order: yup.string().required("Обязательное поле"),
    sum: yup.string().required("Обязательное поле"),
    items: yup.mixed().required("Обязательное поле")
}).required();

const ReturnRequestForm = () => {
    const [currentOrder, setOrder] = useState<Order | null>(null);
    const history = useHistory();
    const { openModal } = useModal();
    const { enqueueSnackbar } = useSnackbar();
    const { state } = useLocation<any>();
    const [status, setStatus] = useState(LoadingStatuses.loading)
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        setError,
        formState:{ errors, isSubmitting }
    } = useForm<any>({
        resolver: yupResolver(schema),
        defaultValues: {
            fio: {},
            user: "",
            phone: "",
            email: "",
            address: "",
            order: "",
            sum: "",
            track_number: "",
            is_partial_return: false,
            orderItems: [],
            items: [],
            is_exchanged: false,
            comment: "",
            new_order: null
        }
    });

    const successSave = () => {
        history.push(Paths.RETURN_REQUESTS.path)
        setStatus(LoadingStatuses.loaded)
    }

    const handleErrorRequest = (err) => {
        if(err?.response) {
            const { data } = err?.response;
            enqueueSnackbar(data?.detail || "Произошла ошибка", { variant: "error" });
            Object.keys(data).forEach(key => {
                setError(key, { type: 'custom', message: data[key] });
            })
            setStatus(LoadingStatuses.loaded)
        } else {
            setStatus(LoadingStatuses.failed)
        }
    }

    const onSubmit = (data) => {
        // console.log(data, 'onSubmit data')
        setStatus(LoadingStatuses.loading)
        const id = state?.id || null;
        const newItems = [...data.items].map(el => {
            if(el.id) {
                return el.id
            }
            return el
        })
        const params = {
            is_partial_return: data.is_partial_return,
            track_number: data.track_number,
            sum: data.sum,
            is_exchanged: data.is_exchanged,
            user: data.user,
            order: Number(data.order),
            comment: data.comment,
            items: newItems,
            new_order: data.is_exchanged ? data.new_order : null
        }
        if(id) {
            api.updateReturnRequests(id, params)
                .then(res => successSave())
                .catch(err => handleErrorRequest(err))
        } else {
            api.createReturnRequests(params)
                .then(res => successSave())
                .catch(err => handleErrorRequest(err))
        }
        
    }

    useEffect(() => {
        const id = state?.id || null
        
        if(id) {
            api.getReturnRequest(id)
                .then(res => {
                    const newValues = [
                        { name: "fio", value: {
                            label: res?.user?.fullName || res?.user?.username,
                            value: {
                                id: res?.user?.id
                            }
                        }},
                        { name: "phone", value: res?.user?.phone },
                        { name: "email", value: res?.user?.email },
                        { name: "address", value: res?.user?.address?.formatFullAddress },
                        { name: "user", value: res.order?.user?.id },
                        { name: "order", value: `${res?.order?.id}` },
                        { name: "orderItems", value: res?.order?.items },
                        { name: "items", value: res?.items },
                        { name: "sum", value: res?.sum },
                        { name: "comment", value: res?.comment },
                        { name: "is_exchanged", value: res?.isExchanged },
                        { name: "is_partial_return", value: res?.isPartialReturn },
                        { name: "track_number", value: res?.trackNumber },
                        { name: "new_order", value: res?.newOrder || null }
                    ]
                    newValues.map(({ name, value }) => setValue<any>(name, value))
                    setStatus(LoadingStatuses.loaded)
                })
                .catch(err => setStatus(LoadingStatuses.failed))
        } else {
            setStatus(LoadingStatuses.loaded)
        }

    }, [state])

    const userId = watch("user")
    const isExchanged = watch("is_exchanged")
    const isNewOrder = Boolean(watch("new_order"))
    
    const handleChange = (value) => {
        const params = value.value;
        if(params) {
            setValue("phone", params?.phone || "")
            setValue("email", params.email)
            setValue("address", params?.address?.formatFullAddress || "")
            setValue("user", `${params.id}`)
            setValue("order", "")
            setValue("items", [])
            setValue("fio", value)
        }
    }

    const openCreateModal = () => {
        openModal("CREATE_ORDER", {
            isExchanged: isExchanged,
            orderId: watch("order"),
            onUpdate: (newOrder) => {
                setValue("new_order", newOrder)
            }
        });
    }

    const { items, order, is_partial_return } = watch()

    useEffect(() => {
        if(order) {
            api.getOrderDetail(Number(order))
                .then(res => setOrder(res))
        }
    }, [order])

    useEffect(() => {
        if(currentOrder) {
            const elements = is_partial_return ? items : currentOrder.items;
            const itemsId = elements.map((item: any) => item?.id)
            const sum = currentOrder.items.filter(item => itemsId.includes(item.id)).reduce((acc, obj) => acc + obj.count * obj.salePrice, 0)
            setValue("sum", String(sum))
        }
    }, [items, currentOrder, is_partial_return])

    return (
        <>
            {status === LoadingStatuses.loading ? (
                <CircularPreloader />
            ) : null}
            {status === LoadingStatuses.loaded ? (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Box gridColumn="span 3">
                            <InputLabel htmlFor="user-autocomplete">Клиент</InputLabel>
                        </Box>
                        <Box gridColumn="span 6">
                            <UserAutocomplete
                                id="user-autocomplete"
                                {...register("fio", { required: true })}
                                value={watch("fio")}
                                name="fio"
                                handleChange={handleChange}
                                helperText={errors.fio?.label?.message}
                                error={Boolean(errors.fio?.label?.message)}
                            />
                        </Box>
                    </Row>
                    <Row>
                        <Box gridColumn="span 3">
                            <InputLabel htmlFor="user-phone">Телефон</InputLabel>
                        </Box>
                        <Box gridColumn="span 6">
                            <Input
                                id="user-phone"
                                fullWidth
                                {...register("phone", { required: true })}
                            />
                        </Box>
                    </Row>
                    <Row>
                        <Box gridColumn="span 3">
                            <InputLabel htmlFor="user-email">Email</InputLabel>
                        </Box>
                        <Box gridColumn="span 6">
                            <Input
                                id="user-email"
                                fullWidth
                                {...register("email")}
                            />
                        </Box>
                    </Row>
                    <Row>
                        <Box gridColumn="span 3">
                            <InputLabel htmlFor="user-address">Адрес</InputLabel>
                        </Box>
                        <Box gridColumn="span 6">
                            <Input
                                id="user-address"
                                fullWidth
                                {...register("address")}
                            />
                        </Box>
                    </Row>
                    <Row>
                        <Box gridColumn="span 3">
                            <InputLabel htmlFor="order">Заказ</InputLabel>
                        </Box>
                        <Box gridColumn="span 6">
                            <OrderAutocomplete
                                user={userId}
                                id="order"
                                setValue={setValue}
                                value={watch("order")}
                                {...register("order", { required: true })}
                                helperText={errors.order?.message}
                                error={Boolean(errors.order?.message)}
                            />
                        </Box>
                    </Row>
                    <Row>
                        <Box gridColumn="span 12">
                            <FormControlLabel
                                control={<Checkbox
                                    {...register("is_partial_return", { required: false })}
                                    checked={watch("is_partial_return")}
                                />}
                                label="Частичный возврат"
                            />
                        </Box>
                    </Row>
                    <Row>
                        <Box gridColumn="span 3">
                            <InputLabel htmlFor="order-products">Товар для возврата</InputLabel>
                        </Box>
                        <Box gridColumn="span 6">
                            <OrderProductsAutocomplete
                                items={watch("orderItems")}
                                id="order-products"
                                setValue={setValue}
                                {...register("items", { required: true })}
                                value={watch("items")}
                                user={watch("user")}
                                // helperText={errors?.items?.message}
                                // error={Boolean(errors?.items?.message)}
                            />
                        </Box>
                    </Row>
                    <Row>
                        <Box gridColumn="span 3">
                            <InputLabel htmlFor="sum">Сумма</InputLabel>
                        </Box>
                        <Box gridColumn="span 6">
                            <Input
                                id="sum"
                                fullWidth
                                {...register("sum")}
                                helperText={errors.sum?.message}
                                error={Boolean(errors.sum?.message)}
                            />
                            {currentOrder ? `(полная стоимость заказа - ${currentOrder.totalPrice})`: ''}
                        </Box>
                    </Row>
                    <Row>
                        <Box gridColumn="span 3">
                            <InputLabel htmlFor="track-number">Трек номер</InputLabel>
                        </Box>
                        <Box gridColumn="span 6">
                            <Input
                                id="track-number"
                                fullWidth
                                {...register("track_number")}
                                helperText={errors.track_number?.message}
                                error={Boolean(errors.track_number?.message)}
                            />
                        </Box>
                    </Row>
                    <Row>
                        <Box gridColumn="span 3">
                            <InputLabel htmlFor="comment">Комментарий</InputLabel>
                        </Box>
                        <Box gridColumn="span 6">
                            <Input
                                id="comment"
                                fullWidth
                                multiline
                                rows={4}
                                {...register("comment")}
                            />
                        </Box>
                    </Row>
                    <Row>
                        <Box gridColumn="span 6">
                            <FormControlLabel
                                control={<Checkbox
                                    {...register("is_exchanged", { required: false })}
                                    checked={watch("is_exchanged")}
                                />}
                                label="Будет осуществлен обмен"
                            />
                        </Box>
                        {isExchanged && !isNewOrder ? (
                            <Box gridColumn="span 6">
                                <Button onClick={openCreateModal}>Создать новый заказ</Button>
                            </Box>
                        ) : null}
                        {isExchanged && isNewOrder ? (
                            <Box gridColumn="span 6">
                                Заказ № {watch("new_order")}
                            </Box>
                        ) : null}
                    </Row>
                    <Row>
                        <Box gridColumn="span 6">
                            <Button disabled={isSubmitting} variant="contained" type="submit">Сохранить</Button>
                        </Box>
                    </Row>
                </form>
            ) : null}
            {status === LoadingStatuses.failed ? (
                <div>Произошла ошибка загрузки</div>
            ) : null}
        </>
    )
}

export default ReturnRequestForm;